import {call, put, takeLatest} from 'redux-saga/effects';
import {
  fetchCountryDataCreatorTypeName,
  fetchStateDataCreatorTypeName,
  setStateList,
  setCountryList,
  fetchHolidayByYearDataCreatorTypeName,
  setHoildayByYearList,
  fetchDivisionDataCreatorTypeName,
  setHoildayDivisionList,
  setLoading,
  fetchAllowedHolidayByCountryYearCreatorTypeName,
  setAllowedHolidayByCountryYearList,
} from './slice';
import {NetworkService} from '@app/services';
import {API_PATH} from '@app/common/constants';
/**
 * Watcher saga for fetching country data
 */
export function* fetchCountryWatcher() {
  yield takeLatest(fetchCountryDataCreatorTypeName, fetchCountryDataWorker);
}
/**
 * Watcher saga for fetching state data
 */
export function* fetchStateeWatcher() {
  yield takeLatest(fetchStateDataCreatorTypeName, fetchStateDataWorker);
}

/**
 * Watcher saga for fetching holiday list by year
 */
export function* fetchHolidatByYearWatcher() {
  yield takeLatest(
    fetchHolidayByYearDataCreatorTypeName,
    fetchHolidayByYearDataWorker,
  );
}

/**
 * Watcher saga for fetching holiday list by year
 */
export function* fetchHolidayDivisionWatcher() {
  yield takeLatest(
    fetchDivisionDataCreatorTypeName,
    fetchHolidayDivisionDataWorker,
  );
}

export function* fetchAllowedHolidayByCountryYearWatcher() {
  yield takeLatest(
    fetchAllowedHolidayByCountryYearCreatorTypeName,
    fetchAllowedHolidayByCountryYearDataWorker,
  );
}

/**
 * Worker saga for fetching country data
 */
export function* fetchCountryDataWorker(): any {
  try {
    const url = API_PATH.holidayManagement.getcountrylist;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(setCountryList(response?.data || []));
  } catch (error) {
    console.error('Error fetching fetchCountryDataWorker:', error);
    // Handle the error as needed (e.g., dispatch an action)
  }
}

/**
 * Worker saga for fetching state data
 */
export function* fetchStateDataWorker(actions: any): any {
  const {CountryId} = actions.payload;
  try {
    const url = API_PATH.holidayManagement.getStateListByCountryID;
    const response = yield call(NetworkService.get, url, {CountryId}, {});
    yield put(setStateList(response?.data || []));
  } catch (error) {
    console.error('Error fetching fetchStateDataWorker:', error);
    // Handle the error as needed (e.g., dispatch an action)
  }
}

/**
 * Worker saga for fetching holiday by year data
 */
export function* fetchHolidayByYearDataWorker(actions: any): any {
  const {year, pageIndex, pageSize} = actions.payload;
  yield put(setLoading(true));
  try {
    const url =
      API_PATH.holidayManagement.getHolidayByYear +
      year +
      '&PageIndex=' +
      pageIndex +
      '&PageSize=' +
      pageSize;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(setHoildayByYearList(response?.data || []));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchHolidayByYearDataWorker:', error);
    // Handle the error as needed (e.g., dispatch an action)
  }
}
/**
 * Worker saga for fetching holiday division
 */
export function* fetchHolidayDivisionDataWorker(actions: any): any {
  try {
    const url = API_PATH.holidayManagement.divisionByAdmin;
    const response = yield call(NetworkService.get, url, {}, {});
    yield put(setHoildayDivisionList(response?.data || []));
  } catch (error) {
    console.error('Error fetching fetchHolidayByYearDataWorker:', error);
    // Handle the error as needed (e.g., dispatch an action)
  }
}

export function* fetchAllowedHolidayByCountryYearDataWorker(actions: any): any {
  const {year, CountryId} = actions.payload;
  try {
    const payload = {
      year: year,
      CountryId: CountryId,
    };
    yield put(setLoading(true));
    const url =
      API_PATH.holidayManagement.getAllowedHolidayCountListByYearCountry;
    const response = yield call(NetworkService.get, url, payload, {});
    yield put(setAllowedHolidayByCountryYearList(response?.data || []));
    yield put(setLoading(false));
  } catch (error) {
    yield put(setLoading(false));
    console.error('Error fetching fetchHolidayByYearDataWorker:', error);
    // Handle the error as needed (e.g., dispatch an action)
  }
}
