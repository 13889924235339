import Typography from '@mui/material/Typography';
import {Box, Tabs, Tab} from '@mui/material';
import {
  RootStateOrAny,
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  SwitchingNo,
  setRightSidebarToggle,
  setScreenNo,
  updateAdminTabs,
  updateHotabs,
} from '../../../screens/zho/pages/fc-admin/redux/tabName';
import {useEffect} from 'react';
import {Stack} from '@mui/system';
import {SwitchingName} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import Colors from '@app/themes/colors';
import {useLocation} from 'react-router-dom';

export const RequestFormNavbar = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const tabNames = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.names,
    shallowEqual,
  );

  const headerActiveIndex = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.activeTabIndex,
  );

  const zhoRightSidebarVisible = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.RightSidebar,
    shallowEqual,
  );

  const mainHeaderFcCount = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.fcadminHeaderCountNo,
  );

  const mainHeaderHoCount = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hoadminHeaderCountNo,
  );

  const mainHeaderFcCountLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.fcadminHeaderCountLoading,
  );

  const mainHeaderHoCountLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hoadminHeaderCountLoading,
  );

  const adminTabs = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.adminTabs,
  );
  const hoTabs = useSelector(
    (state: RootStateOrAny) => state?.tabNames.value.hoTabs,
  );

  useEffect(() => {
    if (pathname === '/fc-admin') {
      dispatch(setScreenNo(1));
      dispatch(SwitchingName(adminTabs));
    } else if (pathname === '/ho-admin') {
      dispatch(setScreenNo(2));
      dispatch(SwitchingName(hoTabs));
    }
    return () => {
      dispatch(SwitchingName([]));
    };
  }, [pathname, adminTabs, hoTabs]);

  useEffect(() => {
    dispatch(
      updateAdminTabs({
        index: 1,
        value: `Order(${mainHeaderFcCount?.pendingOrdersCount || 0})`,
      }),
    );

    dispatch(
      updateHotabs({
        index: 1,
        value: `Invoicing(${mainHeaderHoCount?.pendingPODApprovalCount || 0})`,
      }),
    );
  }, [mainHeaderFcCountLoading, mainHeaderHoCountLoading]);

  const handleRightPanelClick = () => {
    dispatch(setRightSidebarToggle(true));
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        dispatch(SwitchingNo(0));
        break;
      case 1:
        dispatch(SwitchingNo(1));
        break;
      case 2:
        dispatch(SwitchingNo(2));
        break;
      case 3:
        dispatch(SwitchingNo(3));
        break;
      case 4:
        dispatch(SwitchingNo(4));
        break;
      case 5:
        dispatch(SwitchingNo(5));
        break;
      default:
        dispatch(SwitchingNo(0));
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      padding: '0px',
      transform: 'translateY(20px)',
      marginTop: '-20px',
    },
    tab: {
      color: '#322B7C',
      fontSize: '0.7rem',
      padding: '8px 25px',
      marginRight: `${tabNames.length > 3 ? '0px' : '4rem'}`,
      minHeight: '2rem',
      height: '2.5rem',
      textTransform: 'none',
    },
    goButton: {
      border: `3px solid ${Colors.primary}`,
      backgroundColor: Colors.primary,
      borderRadius: '5px',
      width: 'auto',
      height: '35px',
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: '600',
      fontStyle: 'normal',
      letterSpacing: 0,
      textAlign: 'center',
      marginLeft: 10,
      color: Colors.white,
      cursor: 'pointer',
      '&:hover': {
        background: Colors.primary,
      },
      '&:disabled': {
        backgroundColor: Colors.grey[100],
        border: `3px solid ${Colors.grey[100]}`,
      },
      '& .css-1r6mvst-MuiToolbar-root': {
        marginRight: '0px !important',
      },
    },
  };

  return (
    <Box sx={styles.container}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="h6"
          noWrap
          fontSize="22.7px"
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          ZHO
        </Typography>
        <Box>
          {pathname === '/ho-admin' && (
            <svg
              onClick={handleRightPanelClick}
              style={{
                transform: zhoRightSidebarVisible
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                cursor: 'pointer',
              }}
              // onClick={handeBackToOrderPopup}
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle
                cx="16.1135"
                cy="16.1135"
                r="15.6099"
                transform="matrix(1 0 0 -1 0.539062 32.3574)"
                stroke="#322B7C"
                stroke-width="1.00709"
              />
              <path
                d="M20.5416 23.4673L12.7627 16.244L20.5416 8.46508"
                stroke="#322B7C"
                stroke-width="2.01418"
              />
            </svg>
          )}
        </Box>
      </Stack>
      <Tabs
        aria-label="request form tabs"
        indicatorColor="primary"
        textColor="primary"
        sx={{
          minHeight: '0.5rem',
          marginTop: '2rem',
        }}
        onChange={handleTabChange}
        value={headerActiveIndex}>
        {tabNames.map((name: any, index: number) => (
          <Tab
            key={index}
            sx={[
              styles.tab,
              {
                fontSize: '12.7px',
                fontWeight: `${
                  headerActiveIndex === index ? 'bold' : 'normal !important'
                }`,
              },
            ]}
            label={name}
          />
        ))}
      </Tabs>
    </Box>
  );
};
