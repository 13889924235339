import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Checkbox,
  Container,
  Button,
  Tooltip,
  tooltipClasses,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import {useStyles} from '../../style';
import {Fragment, useEffect, useRef, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {Stack, styled} from '@mui/system';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  fetchErrorInfoMsgCreator,
  fetchInvoiceFormDataCreator,
  fetchInvoiceProductApproveCreator,
  fetchOrderFormApprovalDataCreator,
  fetchOrderFormProductDataCreator,
  fetchOrderInnerFormDataCreator,
} from '../../../redux-api/slice';
import {strings} from '@app/common/strings';
import {ToastType} from '@app/services/toaster';
import {ToasterService} from '@app/services';
import dateUtils from '@app/screens/zho/utils/dateFormat';
import OrderProductDetails from '../../../Order/components/orderProductDetails';
import {setStatusToggle} from '@app/screens/zho/pages/fc-admin/redux/tabName';
import {
  setCurrentOrderId,
  updateRequestPayloadOrder,
} from '../../../redux/orderStore';
import theme from '@app/themes';

interface CheckBoxState {
  id: number;
  checked: boolean;
}

export const InvoiceFormTable = ({
  handleToggleModal,
  setOrderFormId,
  userLoginInfo,
}: any) => {
  const classes = useStyles();
  const {DateFormat} = dateUtils;
  const [openCompetitor, setOpenCompetitor] = useState({id: 0, open: false});
  const [orderIds, setOrderIds] = useState<number[]>([]);
  const [openTooltipId, setOpenTooltipId] = useState<number | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const orderId = useRef('');
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);

  const tableData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormData,
  );

  const statusToggleNo = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.statusToggleNo,
  );

  const orderFormPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.requestPayloadOrder,
  );

  const orderFormDataLoader = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderFormLoading,
  );

  const orderInnerFormData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderInnerFormData,
  );

  const orderInnerFromLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderInnerFormLoading,
  );

  const ApprovalOrderComplete = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderProductApprovalComplete,
  );

  const ToolTipErrorList = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderErrorInfoMsgData,
  );


  const ToolTipErrorListLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderErrorInfoMsgLoading,
  );

  const [checkBox, setCheckBox] = useState<CheckBoxState[]>([]);

  const toggleCheckBox = (type: any, id: number) => {
    setCheckBox((prevCheckBox: any) => {
      let updatedorderIds: number[] = [...orderIds];

      if (type === 'all') {
        const allChecked = prevCheckBox.every(
          (item: any) => item.checked === true,
        );
        const newCheckBoxState = prevCheckBox.map((item: any) => ({
          ...item,
          checked: !allChecked,
        }));

        // Update orderIds based on allChecked status
        updatedorderIds = allChecked
          ? []
          : tableData.orderList?.map((orderData: any) => orderData.orderId) ||
            [];

        setOrderIds(updatedorderIds);
        return newCheckBoxState;
      } else {
        const newCheckBoxState = prevCheckBox.map((item: any) => {
          if (item.id === type) {
            return {
              ...item,
              checked: !item.checked,
            };
          }
          return item;
        });

        // Update orderIds based on individual checkbox state
        const isChecked = newCheckBoxState.find(
          (item: any) => item.id === type,
        )?.checked;
        if (isChecked) {
          updatedorderIds.push(id);
        } else {
          updatedorderIds = updatedorderIds.filter(orderId => orderId !== id);
        }

        setOrderIds(updatedorderIds);
        return newCheckBoxState;
      }
    });
  };
  
  const LightTooltip = styled(({className, ...props}: any) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      // boxShadow: theme.shadows[1],
      fontSize: 11.7,
      border: '1px solid #dcdce0',
      borderRadius: '8px',
      position: 'fixed',
      padding: '1rem',
      paddingTop: ToolTipErrorList?.length > 2 ? '2rem' : '1rem',
      paddingRight: '0rem',
      left: '-280px',
      minWidth: '300px',
      zIndex: '9999 !important',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    },
  }));

  const handleTooltipClose = () => {
    document.body.style.overflow = 'auto';
    setOpenTooltipId(null);
  };

  const showTooltipErrorMsg = (id: number) => {
    document.body.style.overflow = 'hidden';
    dispatch(fetchErrorInfoMsgCreator(id));
  };

  const getStatusColor = (status: number) => {
    if (status === 0) {
      return '#ff7650';
    } else if (status === 1) {
      return '#01a685';
    } else if (status === 3 || status === 4) {
      return '#f45b60';
    } else if (status === 5) {
      return '#34b53a';
    }
  };

  const isRowHighlighted = (index: number, tableData: any): boolean => {
    return tableData?.orderList[index]?.isHighlighted || false;
  };

  const handleInnerClickData = (id: number, index: number) => {
    setOpenCompetitor({
      id: index,
      open: true,
    });
    dispatch(fetchOrderInnerFormDataCreator(id));
  };

  const handleApprovalInvoiceForm = () => {
    if (orderIds.length === 0) {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        'Please select a order to Approve!',
        ToastType.ERROR,
      );
    }
    if (orderIds.length > 0) {
      dispatch(
        fetchInvoiceProductApproveCreator(
          userLoginInfo?.staffPositionId,
          userLoginInfo?.userId,
          orderIds,
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );

    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageSize: rowsPerPage,
        },
        1,
      ),
    );

    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }

    dispatch(setStatusToggle(1));
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
  }, [tableData]);

  useEffect(() => {
    if (isMounted) {
      dispatch(
        fetchInvoiceFormDataCreator(
          {
            ...orderFormPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
          },
          1,
        ),
      );
    }

    if (
      orderId.current !== undefined &&
      orderId.current !== null &&
      orderId.current !== ''
    ) {
      dispatch(fetchOrderFormProductDataCreator(orderId.current));
      dispatch(fetchOrderFormApprovalDataCreator(orderId.current));
    }

    if (tableData?.orderList) {
      const initialCheckBoxState = tableData.orderList.map(
        (data: any, index: number) => ({
          id: index,
          checked: false,
        }),
      );
      setCheckBox(initialCheckBoxState);
    }
    setOrderIds([]);
  }, [ApprovalOrderComplete]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    setOrderIds([]);
    setOpenCompetitor({id: 0, open: false});
  }, [statusToggleNo]);

  const handleOpenReviewPopup = (orderId: number) => {
    handleToggleModal();
    dispatch(fetchOrderFormProductDataCreator(orderId));
    dispatch(fetchOrderFormApprovalDataCreator(orderId));
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);

    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageNumber: newPage,
          pageSize: rowsPerPage,
        },
        statusToggleNo,
      ),
    );

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: rowsPerPage,
        pageNumber: newPage, // API expects one-based page number
      }),
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setPage(0); // Reset to the first page (zero-based for Material-UI)
    dispatch(
      fetchInvoiceFormDataCreator(
        {
          ...orderFormPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          pageNumber: 0,
          pageSize: newPageSize,
        },
        statusToggleNo,
      ),
    );

    dispatch(
      updateRequestPayloadOrder({
        ...orderFormPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: newPageSize,
        pageNumber: 0, // API expects one-based page number
      }),
    );
  };

  return (
    <Container
      sx={{
        marginTop: '10px',
        // position: 'relative',
        maxWidth: '100% !important',
        padding: '0px  !important',
      }}>
        
      <Box
        sx={{display: openTooltipId !== null ? 'block' : 'none'}}
        className={classes.tooltipOverlay}></Box>
      {statusToggleNo === 1 && (
        <Stack
          marginBottom="12px"
          direction="row"
          justifyContent="flex-end"
          alignItems="center">
          <Box>
            {/* <Button
              data-testid="button-reject"
              variant="outlined"
              size="large"
              className={classes.paperButton}>
              {strings.reject}
            </Button> */}
            <Button
              data-testid="button-approve"
              variant="contained"
              className={classes.applyButton}
              onClick={handleApprovalInvoiceForm}>
              {strings.approve}
            </Button>
          </Box>
        </Stack>
      )}

      {!orderFormDataLoader ? (
        <>
          <TableContainer className={`${classes.noBoxShd}`} component={Paper}>
            <Table sx={{borderCollapse: 'separate', borderSpacing: '0px 1px'}}>
              <TableHead>
                <TableRow className={`${classes.requestformTableHeading}   `}>
                  {statusToggleNo === 1 && tableData?.orderList?.length > 0 && (
                    <TableCell
                      sx={{
                        border: 'none',
                        padding: '0px !important',
                        // position: 'sticky',
                        // left: '0px',
                        // background: '#f4f4f4',
                      }}>
                      <Checkbox
                        sx={{color: '#322b7c !important'}}
                        data-testid="check-all-requestform"
                        checked={
                          checkBox?.length > 0 &&
                          checkBox.every((data: any) => data?.checked === true)
                        }
                        onClick={() => toggleCheckBox('all', 0)}
                      />
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '160px !important',
                      paddingLeft: '44px !important',
                      // position: 'sticky',
                      // left: statusToggleNo === 1 ? '40px' : '0px',
                    }}
                    className={`${classes.requestformTableHeading}  ${classes.cellPadding} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.orderNo}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '160px !important',
                      paddingLeft:
                        statusToggleNo === 1 ? '' : '10px !important',
                    }}
                    className={`${classes.requestformTableHeading}  ${classes.cellPadding}`}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.requestFormNo}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px', minWidth:'90px'}}
                    className={`${classes.requestformTableHeading} `}>
                    {strings.zho.creationDate}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      minWidth: '95px',
                    }}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding}`}>
                    {strings.zho.customer}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.hq}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingWidth} `}>
                    {strings.status}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', minWidth: '130px', textWrap: 'nowrap'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} `}>
                    {strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis}
                  </TableCell>

                  <TableCell
                    sx={{border: 'none', minWidth: '130px'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} ${classes.requestFormHeadingLgWidth} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.schemeStatus}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingLgWidth} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.SapOrderNo}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px', minWidth: '120px'}}
                    className={`${classes.requestformTableHeading}`}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.SapOrderRemarks}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', padding: '0px', minWidth: '95px'}}
                    className={`${classes.requestformTableHeading}  `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.InvoiceNo}
                  </TableCell>
                  <TableCell
                    sx={{border: 'none', minWidth: '110px'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding}`}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.invoiceDate}
                  </TableCell>
                  {/* <TableCell
                    sx={{border: 'none', padding: '0px'}}
                    className={`${classes.requestformTableHeading} ${classes.requestFormHeadingWidth} `}>
                    {strings.zho.hoAdmin.dashboard.tableHeading.supply}
                  </TableCell> */}
                  <TableCell
                    sx={{border: 'none'}}
                    className={`${classes.requestformTableHeading} ${classes.cellPadding} `}>
                    {strings.actions}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.orderList?.length > 0 ? (
                  tableData?.orderList?.map((data: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow className={classes.requestformTbleBoxSdw}>
                          {statusToggleNo === 1 && (
                            <TableCell
                              className={classes.checkBoxShd}
                              sx={{
                                border: 'none !important',
                                padding: '0px !important',
                                // position: 'sticky',
                                // left: '0px',
                                // background: '#f4f4f4',
                              }}>
                              <Checkbox
                                sx={{color: '#322b7c !important'}}
                                data-testid="check-requestform"
                                checked={checkBox[index]?.checked || false}
                                onClick={() =>
                                  toggleCheckBox(index, data?.orderId)
                                }
                              />
                            </TableCell>
                          )}

                          <TableCell
                            sx={{
                              padding: '0px 6px !important',
                              minWidth: '160px',
                              // position: 'sticky',
                              // left: statusToggleNo === 1 ? '40px' : '0px',
                              // background: '#f4f4f4',
                            }}
                            className={` ${classes.checkBoxShd} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }  ${classes.requestFormTableFont} ${
                              classes.productTableLeftBorder
                            } ${classes.borderTopLeft} `}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center">
                              <Box width="30px" display="inline-block">
                                <IconButton
                                  data-testid="show-comp-prod"
                                  onClick={e => {
                                    e.stopPropagation();
                                  }}>
                                  {/* <ReplayIcon color="primary" /> */}
                                  {openCompetitor.open &&
                                  openCompetitor.id === index ? (
                                    <RemoveCircleOutlineOutlinedIcon
                                      sx={{
                                        height: '18px',
                                        width: '18px',
                                      }}
                                      color="primary"
                                      data-testid="hide-prod-details"
                                      onClick={() =>
                                        setOpenCompetitor({
                                          id: index,
                                          open: false,
                                        })
                                      }
                                    />
                                  ) : (
                                    <AddCircleOutlineOutlinedIcon
                                      sx={{height: '18px', width: '18px'}}
                                      color="primary"
                                      data-testid="show-prod-details"
                                      onClick={() =>
                                        handleInnerClickData(
                                          data?.orderId,
                                          index,
                                        )
                                      }
                                    />
                                  )}
                                </IconButton>
                              </Box>
                              <Box
                                onClick={() => {
                                  handleOpenReviewPopup(data?.orderId);
                                  setOrderFormId(data?.orderId);
                                  orderId.current = data?.orderId;
                                  dispatch(setCurrentOrderId(data?.orderId));
                                }}
                                sx={{
                                  fontWeight: '500',
                                  textDecoration: 'underline',
                                  color: theme.colors.primary,
                                  cursor: 'pointer',
                                }}
                                fontSize="11.7px"
                                minWidth="180px"
                                display="inline-block">
                                {data?.orderFormNo}
                              </Box>
                            </Stack>
                          </TableCell>

                          <TableCell
                            sx={{
                              wordBreak: 'break-all !important',
                              minWidth: '180px',
                              paddingLeft:
                                statusToggleNo === 1 ? '' : '10px !important',
                            }}
                            className={`${classes.checkBoxShd}  ${
                              classes.cellPadding
                            } ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }   ${classes.requestFormTableFont}`}>
                            {data?.requestFormNo}
                          </TableCell>
                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {DateFormat(data?.createdOn)}
                          </TableCell>

                          <TableCell
                            sx={{
                              minWidth: '90px',
                            }}
                            className={` ${classes.requestFormTableFont} ${classes.cellPadding} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.customer}
                          </TableCell>
                          <TableCell
                            className={` ${classes.requestFormTableFont} ${classes.cellPadding} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.hq}
                          </TableCell>
                          <TableCell
                            style={{
                              color: getStatusColor(data?.statusCode),
                              fontWeight: '600',
                              minWidth: '90px',
                            }}
                            className={` ${classes.cellPadding}  ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            } ${classes.requestFormTableFont}`}>
                            <Stack direction="row" alignItems="center" gap={1}>
                              <Typography
                                className={classes.requestFormTableFont}
                                style={{
                                  color: getStatusColor(data?.statusCode),
                                  padding: '10px !important',
                                }}>
                                {data?.status}
                              </Typography>

                              {/* {data?.statusCode === 3 && (
                                <Stack
                                  alignItems="center"
                                  justifyContent="center">
                                  <LightTooltip
                                    title={
                                      <>
                                        <Typography
                                          fontSize="11.7px"
                                          fontWeight="400"
                                          variant="caption"
                                          key={index}>
                                          {data?.pendingAt}
                                        </Typography>
                                      </>
                                    }>
                                    <InfoOutlinedIcon
                                      data-testid="show-customers"
                                      sx={{height: '15px', width: '15px'}}
                                      color="primary"
                                    />
                                  </LightTooltip>
                                </Stack>
                              )} */}
                            </Stack>
                          </TableCell>
                          <TableCell
                            className={`  ${classes.requestFormTableFont} ${classes.cellPadding} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.chemist}
                          </TableCell>

                          <TableCell
                            sx={{
                              minWidth: '130px',
                              color:
                                data?.schemeStatus === 'Active'
                                  ? '#01a685'
                                  : '#f45b60',
                              fontWeight: '600',
                            }}
                            className={`  ${classes.requestFormTableFont} ${classes.cellPadding} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.schemeStatus}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.sapOrderNo}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.sapOrderRemarks}
                          </TableCell>

                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data?.invoiceNo}
                          </TableCell>

                          <TableCell
                            className={`${classes.requestFormTableFont} ${classes.cellPadding} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                            {data.invoiceDate !== null
                              ? DateFormat(data?.invoiceDate)
                              : '---'}
                          </TableCell>
                          {/* 
                          <TableCell
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {data?.supply}
                          </TableCell> */}

                          <TableCell
                          sx={{
                            paddingLeft: '0px !important',

                            '& .MuiTooltip-tooltip': {
                              marginTop: `${
                                ToolTipErrorList.length > 2
                                  ? '-123px !important'
                                  : '14px !important'
                              }`,
                            },
                            // position: 'relative',
                          }}
                            className={`  ${classes.requestFormTableFont} ${classes.cellPadding}   ${classes.borderTopRight} ${classes.productTableRightBorder} ${
                              isRowHighlighted(index, tableData)
                                ? classes.highlightBorderwithStatus
                                : ''
                            }`}>
                              {isRowHighlighted(index, tableData) && (
                              <Box
                                sx={{
                                  '& .base-Popper-root': {
                                    zIndex: '9999',
                                  },

                                  '& .MuiTooltip-tooltip': {
                                    marginTop: `${
                                      ToolTipErrorList.length > 2
                                        ? '-123px !important'
                                        : '12px !important'
                                    }`,
                                  },
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  // position: 'absolute',
                                  // right: '12px',
                                  // top: '3px',
                                }}>
                                <ClickAwayListener
                                  onClickAway={handleTooltipClose}>
                                  <Box
                                    sx={{
                                      '& .MuiTooltip-popper': {
                                        zIndex: '9999',
                                      },
                                    }}
                                    onClick={event => {
                                      event.stopPropagation();
                                      if (openTooltipId !== data.orderId) {
                                        setTimeout(() => {
                                          setOpenTooltipId(
                                            openTooltipId === data.orderId
                                              ? null
                                              : data.orderId,
                                          );
                                        }, 100);
                                        setOpenTooltipId(null);
                                      }
                                    }}>
                                    <LightTooltip
                                      PopperProps={{
                                        disablePortal: true,
                                        modifiers: [
                                          {
                                            name: 'offset',
                                            options: {
                                              offset: [0, -140], // Adjust the second value to move the tooltip up
                                            },
                                          },
                                        ],
                                      }}
                                      onClose={handleTooltipClose}
                                      open={openTooltipId === data?.orderId}
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                      title={
                                        <>
                                          {openTooltipId !== null && (
                                            <>
                                              <Box
                                                sx={{
                                                  height: 'auto',
                                                  maxHeight: '250px',
                                                  minHeight: '70px',
                                                  overflowY: 'auto',
                                                  paddingRight: '1.2rem',
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  justifyContent: 'center',
                                                  alignItems: 'flex-start',
                                                }}
                                                position="relative">
                                                {!ToolTipErrorListLoading ? (
                                                  ToolTipErrorList.map(
                                                    (
                                                      data: any,
                                                      index: number,
                                                    ) => {
                                                      return (
                                                        <Stack
                                                          borderBottom={1}
                                                          borderColor={
                                                            'lightgray'
                                                          }
                                                          marginTop={2}
                                                          marginBottom={0.4}
                                                          key={index}
                                                          direction="column"
                                                          gap={0.2}>
                                                          <Typography
                                                            fontSize="11.7px"
                                                            color="primary"
                                                            fontWeight="400"
                                                            variant="caption">
                                                            {data}
                                                          </Typography>
                                                        </Stack>
                                                      );
                                                    },
                                                  )
                                                ) : (
                                                  <Box
                                                    className={classes.loader}
                                                    sx={[
                                                      {
                                                        display: 'flex',
                                                        justifyContent:
                                                          'center',
                                                        mt: 3,
                                                      },
                                                    ]}>
                                                    <CircularProgress />
                                                  </Box>
                                                )}
                                              </Box>
                                              <svg
                                                onClick={handleTooltipClose}
                                                style={{
                                                  position: 'absolute',
                                                  top: '6px',
                                                  right: '6px',
                                                }}
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect
                                                  width="24"
                                                  height="24"
                                                  rx="12"
                                                  fill="#E5E5E5"
                                                />
                                                <path
                                                  d="M12 10.8891L15.8891 7L17 8.11094L13.1109 12L17 15.8891L15.8891 17L12 13.1109L8.11094 17L7 15.8891L10.8891 12L7 8.11094L8.11094 7L12 10.8891Z"
                                                  fill="#4F4F4F"
                                                />
                                              </svg>
                                            </>
                                          )}
                                        </>
                                      }>
                                      <svg
                                        onClick={() =>
                                          showTooltipErrorMsg(data?.orderId)
                                        }
                                        style={{cursor: 'pointer'}}
                                        // style={{
                                        //   transform: 'translate(57px,-5px)',
                                        // }}
                                        width="13"
                                        height="13"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_222_5448)">
                                          <path
                                            d="M7.87112 13.6799C6.24338 13.6799 4.6823 13.0333 3.53131 11.8823C2.38032 10.7313 1.73371 9.17025 1.73371 7.5425C1.73371 5.91476 2.38032 4.35369 3.53131 3.2027C4.6823 2.05171 6.24338 1.40509 7.87112 1.40509C9.49886 1.40509 11.0599 2.05171 12.2109 3.2027C13.3619 4.35369 14.0085 5.91476 14.0085 7.5425C14.0085 9.17025 13.3619 10.7313 12.2109 11.8823C11.0599 13.0333 9.49886 13.6799 7.87112 13.6799ZM7.87112 14.5567C9.7314 14.5567 11.5155 13.8177 12.8309 12.5023C14.1463 11.1869 14.8853 9.40278 14.8853 7.5425C14.8853 5.68223 14.1463 3.89814 12.8309 2.58273C11.5155 1.26731 9.7314 0.52832 7.87112 0.52832C6.01084 0.52832 4.22676 1.26731 2.91134 2.58273C1.59593 3.89814 0.856934 5.68223 0.856934 7.5425C0.856934 9.40278 1.59593 11.1869 2.91134 12.5023C4.22676 13.8177 6.01084 14.5567 7.87112 14.5567Z"
                                            fill="#DA3131"
                                          />
                                          <path
                                            d="M8.68667 6.30464L6.67886 6.55628L6.60696 6.88945L7.00151 6.96222C7.25928 7.0236 7.31014 7.11654 7.25402 7.37343L6.60696 10.4141C6.43687 11.2005 6.69902 11.5705 7.3154 11.5705C7.79324 11.5705 8.34824 11.3496 8.59987 11.0462L8.67702 10.6815C8.50167 10.8358 8.24565 10.8972 8.07556 10.8972C7.83445 10.8972 7.74677 10.728 7.80902 10.4299L8.68667 6.30464ZM8.74804 4.47394C8.74804 4.70648 8.65567 4.92949 8.49124 5.09391C8.32682 5.25834 8.1038 5.35071 7.87127 5.35071C7.63874 5.35071 7.41572 5.25834 7.2513 5.09391C7.08687 4.92949 6.9945 4.70648 6.9945 4.47394C6.9945 4.24141 7.08687 4.0184 7.2513 3.85397C7.41572 3.68954 7.63874 3.59717 7.87127 3.59717C8.1038 3.59717 8.32682 3.68954 8.49124 3.85397C8.65567 4.0184 8.74804 4.24141 8.74804 4.47394Z"
                                            fill="#DA3131"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_222_5448">
                                            <rect
                                              width="14.0284"
                                              height="14.0284"
                                              fill="white"
                                              transform="translate(0.856934 0.52832)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </LightTooltip>
                                  </Box>
                                </ClickAwayListener>
                              </Box>
                            )}
                            <Typography
                              onClick={() => {
                                handleOpenReviewPopup(data?.orderId);
                                setOrderFormId(data?.orderId);
                                orderId.current = data?.orderId;
                                dispatch(setCurrentOrderId(data?.orderId));
                              }}
                              data-testid="review-request"
                              sx={{
                                fontSize: '11.7px',
                                cursor: 'pointer',
                              }}
                              variant="subtitle2"
                              color="primary">
                              {strings.zho.review}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {openCompetitor.open && openCompetitor.id === index && (
                          <TableRow sx={{position: 'relative'}}>
                            {!orderInnerFromLoading ? (
                              <TableCell
                                sx={{
                                  padding:
                                    statusToggleNo === 1
                                      ? '0px 0px 8px 40px'
                                      : '0px 0px 8px 0px',
                                  border: 'none !important',
                                }}
                                colSpan={7}>
                                <OrderProductDetails
                                  orderInnerFormData={orderInnerFormData}
                                  hide={true}
                                />
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Box
                                  className={classes.loader}
                                  sx={[
                                    {
                                      display: 'flex',
                                      justifyContent: 'center',
                                    },
                                  ]}>
                                  <CircularProgress />
                                </Box>
                              </TableCell>
                            )}
                          </TableRow>
                        )}
                      </Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{textAlign: 'center'}} colSpan={9}>
                      Currently there are no Invoice!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50, 100]}
            count={+tableData?.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <TableContainer sx={{height: '90px'}}>
          <Table>
            <TableBody>
              <TableRow>
                <Box
                  // className={classes.loader}
                  sx={[
                    {
                      display: 'flex',
                      justifyContent: 'center',
                      mt: 3,
                    },
                  ]}>
                  <CircularProgress />
                </Box>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

export default InvoiceFormTable;
