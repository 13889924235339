import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import './styles.css';
import {strings} from '@app/common/strings';
import {useEffect} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fetchSummaryTotalDataCreator,
  hoDashboardActions,
} from '../../pages/ho-admin/redux-api/slice';
const TotalBoxSummary = ({userLoginInfo}: any) => {
  const summaryPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.summaryPayload,
  );

  const summaryTotalData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.summaryTotalData,
  );

  const summaryTotalDataLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.summaryTotalLoading,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fetchSummaryTotalDataCreator({
        ...summaryPayload,
        division: [userLoginInfo?.division[0]?.id],

        currentStaffPositionId: userLoginInfo?.staffPositionId,
      }),
    );
    return () => {
      dispatch(hoDashboardActions.setSummaryTableData([]));
      dispatch(hoDashboardActions.setSummaryTotalData([]));
    };
  }, []);
  return (
    <>
      <Box className="boxSummary">
        {!summaryTotalDataLoading ? (
          <Box className="innerBoxSummary">
            <Stack direction="row" gap={2}>
              <Box width="25%" className="rightBorder">
                <Box>
                  <Typography
                    textAlign="center"
                    className="totalBoxSummaryLabel">
                    {strings.zho.fcAdmin.summary.tableHeading.totalOrders}
                  </Typography>
                  <Typography
                    textAlign="center"
                    className="totalBoxSummaryValue">
                    {summaryTotalData?.totalOrdersCount}
                  </Typography>
                </Box>
              </Box>

              <Box width="37.5%" className="rightBorder ">
                <Box>
                  <Typography
                    textAlign="center"
                    className="totalBoxSummaryLabel">
                    {strings.zho.fcAdmin.summary.tableHeading.totalSalesValue}
                  </Typography>
                  <Typography
                    textAlign="center"
                    className="totalBoxSummaryValue">
                    {summaryTotalData?.totalSalesValue}
                  </Typography>
                </Box>
              </Box>

              <Box width="37.5%">
                <Box>
                  <Typography
                    textAlign="center"
                    className="totalBoxSummaryLabel">
                    {strings.zho.fcAdmin.summary.tableHeading.averageExtraDis}
                  </Typography>
                  <Typography
                    textAlign="center"
                    className="totalBoxSummaryValue">
                    {summaryTotalData?.totalPercentage !== 'NaN'
                      ? summaryTotalData?.totalPercentage
                      : 0}
                    %
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Box>
        ) : (
          <Box
            className="loader"
            sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default TotalBoxSummary;
