import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  tableCell: {
    border: 'none',
    width: '100%',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },
  container: {
    height: '70vh',
    width: '100%',
    '& .MuiDataGrid-columnHeaders ': {
      borderWidth: 0,
    },
    '& .MuiDataGrid-row': {
      borderWidth: 0,
    },
    '& .MuiDataGrid-row:first-child': {
      borderTopWidth: 2,
      borderRadius: 10,
    },
    '& .MuiDataGrid-cell': {
      borderWidth: 0,
      borderBottomWidth: 2,
    },
    '& .MuiDataGrid-cell:first-child': {
      borderLeftWidth: 2,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 5,
    },
    '& .MuiDataGrid-cell:last-child': {
      borderRightWidth: 2,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 5,
    },
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    // justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiIconButton-root ': {
      background: 'transparent',
      gap: 4,
    },
    '& .MuiSvgIcon-root': {
      width: 15,
      color: '#332b77',
    },
  },
  green: {
    '& .MuiTypography-root': {
      color: '#18A685',
    },
  },
  yellow: {
    '& .MuiTypography-root': {
      color: '#F5AD00',
    },
  },
  red: {
    '& .MuiTypography-root': {
      color: '#DA3131',
    },
  },
  noRecord: {
    marginTop: 20,
  },
  infoIcon: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiInputBase-input': {
    paddingLeft: '0px !important',
  },
  centerAlignCell: {
    textAlign: 'center',
  },
});
