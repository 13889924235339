import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';
import {gridStyles} from '@app/screens/zho/components/widgets/DataGrid/styles';
import theme from '@app/themes';

export const useStyles = makeStyles({
  navbarComponentHeading: {
    width: 200,
    height: 24.7,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#1c1939',
  },

  chipContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    Width: '340px !important',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    padding: '1rem',
    backgroundColor: '##ebe6e6',
  },
  dropdown: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    minWidth: 180,
    maxWidth: 180,
    fontSize: '12px',
  },

  editIconBox: {
    height: '50px',
    width: '120px',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editIcon: {
    height: '20px',
    width: '20px',
  },

  lightBtn: {
    border: '1px solid #9291A5',
    padding: '6px 25px',
    color: '#615e83',
    fontWeight: '400',
    fontSize: '12.7px',
    borderRadius: '8px',
    textTransform: 'capitalize',
  },
  greenBtn: {
    padding: '6px 25px',
    background: '#009429',
    color: '#ffffff',
    fontWeight: '400',
    fontSize: '12.7px',
    borderRadius: '8px',
    textTransform: 'capitalize',
  },
  '&:hover': {
    background: '#8dd9a2 !important',
  },

  manageProTableBorder: {
    border: '1px solid #d4d4d4',
    borderRadius: '8px',
  },

  manageProTableHeadingCell: {
    padding: '8px',
    background: '#f0eff8',
    border: 'none',
    minWidth: '190px',
    maxWidth: '190px',
  },
  manageProTableBodyCell: {
    padding: '8px',
  },
  manageProTableOdd: {
    backgroundColor: '#ffffff',
  },
  manageProTableEven: {
    backgroundColor: '#f0eff8',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },
  textField: {
    border: 'transparent',
    color: '#322B7C',
    borderRadius: '5px',
    width: '100%',
    padding: '0px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '11.7px !important',
    '& input::placeholder': {
      fontSize: '11.7px !important',
    },
    '& input': {
      fontSize: '11.7px',
    },
    '& .MuiAutocomplete-input': {
      width: '.5px !important',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '11.7px !important', // Font size of the placeholder
    },
  },

  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.white,
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '160px',
    height: '35px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: 0,
    textTransform: 'capitalize',
    textAlign: 'center',
    marginLeft: 10,
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `4px solid ${Colors.grey[100]}`,
    },
  },

  rejectionButtonCancel: {
    border: `1.5px solid #322b7c`,
  },

  rejectionButtonRej: {
    backgroundColor: '#da3131',
    border: 'none !important',
    '&:hover': {
      background: '#E17F7F',
    },
  },
  errorBorder: {
    border: '1px solid red !important',
  },

  filterLoader: {
    position: 'absolute',
    left: '130px',
  },

  container: {
    height: '70vh',
    width: '100%',
    '& .MuiDataGrid-columnHeaders ': {
      borderWidth: 0,
    },
    '& .MuiDataGrid-row': {
      borderWidth: 0,
    },
    '& .MuiDataGrid-row:first-child': {
      borderTopWidth: 2,
      borderRadius: 10,
    },
    '& .MuiDataGrid-cell': {
      borderWidth: 0,
      borderBottomWidth: 2,
    },
    '& .MuiDataGrid-cell:first-child': {
      borderLeftWidth: 2,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 5,
    },
    '& .MuiDataGrid-cell:last-child': {
      borderRightWidth: 2,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 5,
    },
  },
  modalSection: {
    padding: '20px !important',
  },

  errorTable: {
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '12.7px',
      color: theme.colors.primary,
      fontWeight: 600,
    },
    '& .MuiDataGrid-cellContent': {
      fontSize: '12.7px !important',
      color: theme.colors.grey[200],
    },
    ...gridStyles,
  },

  modalFooter: {
    borderTop: '1px solid #E3E3E3',
    display: 'flex',
    gap: 1,
  },
  modalHeader: {
    borderBottom: '1px solid #E3E3E3 !important',
    color: theme.colors.grey[200],
    fontSize: 15.7,
  },
  noBorder: {
    border: 'none !important',
  },
});
