import {filterStateActions} from '@app/components/right-sidebar/redux';
import {routeStateActions} from '@app/router/redux';
import {useEffect, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  setRightSidebarToggle,
  setSubTabSwitch,
  updateRequestPayload,
} from '../redux/tabName';
import {navbarComponentName} from '@app/router/redux/routeHandler';

import {Box} from '@mui/material';
import ManageProductView from './components/ManageProductView';
import ManageProductFilter from './components/manageProductFilter';

const ManageProductStatus = ({userLoginInfo}: any) => {
  const dispatch = useDispatch();
  const [addProductPayload, setAddProductPayload] = useState({
    divisionId: 0,
    candFid: [],
    hqid: [],
    partyId: [],
    stockistId: [],
    productId: null,
    createdBy: 0,
  });
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const requestPayload = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.requestPayload,
  );

  console.log('this is the pageno', requestPayload.pageNumber);
  const handlePageChange = (type: string) => {
    if (type === 'inc') {
      setPageNo(pageNo + 1);
      dispatch(
        updateRequestPayload({
          ...requestPayload,
          pageNumber: requestPayload.pageNumber + 1,
        }),
      );
    } else {
      if (pageNo === 0) {
        setPageNo(0);
        dispatch(
          updateRequestPayload({
            ...requestPayload,
            pageNumber: 0,
          }),
        );
      } else {
        setPageNo(pageNo - 1);
        dispatch(
          updateRequestPayload({
            ...requestPayload,
            pageNumber: requestPayload.pageNumber - 1,
          }),
        );
      }
    }
  };

  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.fcAdmin),
    );
    dispatch(setRightSidebarToggle(false));
    return () => {
      dispatch(
        updateRequestPayload({
          ...requestPayload,
          pageNumber: 0,
        }),
      );
      dispatch(filterStateActions.setFilterState({}));
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(setSubTabSwitch(1));
    };
  }, []);

  const clearPayload = () => {
    setAddProductPayload({
      divisionId: 0,
      candFid: [],
      hqid: [],
      partyId: [],
      stockistId: [],
      productId: null,
      createdBy: 0,
    });
  };
  return (
    <Box
      sx={{maxHeight: 'calc(100vh - 155px)', overflow: 'auto'}}
      className="hgghgi-ff">
      <ManageProductFilter
        handlePageChange={handlePageChange}
        pageNo={pageNo}
        addProductPayload={addProductPayload}
        setAddProductPayload={setAddProductPayload}
        userLoginInfo={userLoginInfo}
        margin={true}
        clearPayload={clearPayload}
      />
      <ManageProductView
        handlePageChange={handlePageChange}
        pageNo={pageNo}
        setPageNo={setPageNo}
        setTotalCount={setTotalCount}
        totalCount={totalCount}
        userLoginInfo={userLoginInfo}
      />
    </Box>
  );
};

export default ManageProductStatus;
